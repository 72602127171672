import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';

if (PUBLIC_ENVIRONMENT != 'local') {
	Sentry.init({
		dsn: 'https://777e043387c61da2cc66e280884bd4bb@o4505280176259072.ingest.sentry.io/4505989014814720',
		environment: PUBLIC_ENVIRONMENT,
		tracesSampleRate: 1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
		integrations: [new Sentry.Replay()]
	});
}

export const handleError = Sentry.handleErrorWithSentry();
