import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/admin": [6],
		"/admin/audit-log": [7],
		"/admin/debug-log": [8],
		"/admin/global-roles": [9],
		"/admin/global-users": [10],
		"/auth/meta": [11],
		"/error_logging_in": [12],
		"/stations/[station_id]": [13,[2]],
		"/stations/[station_id]/admin-settings": [14,[2]],
		"/stations/[station_id]/customization": [15,[2]],
		"/stations/[station_id]/deployments": [16,[2]],
		"/stations/[station_id]/deployments/[deployment_id]": [17,[2,3]],
		"/stations/[station_id]/deployments/[deployment_id]/admin-settings": [18,[2,3]],
		"/stations/[station_id]/deployments/[deployment_id]/customization": [19,[2,3]],
		"/stations/[station_id]/deployments/[deployment_id]/server-events": [20,[2,3]],
		"/stations/[station_id]/events": [21,[2]],
		"/stations/[station_id]/events/[event_id]": [22,[2,4]],
		"/stations/[station_id]/events/[event_id]/customization": [23,[2,4]],
		"/stations/[station_id]/events/[event_id]/setup": [24,[2,4]],
		"/stations/[station_id]/players": [25,[2]],
		"/stations/[station_id]/roles": [26,[2]],
		"/stations/[station_id]/server-events": [27,[2]],
		"/user/logout": [28]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';